@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
}

.faded-scrolling-table-body:after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 28px; /* Height of the fade effect */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 1));
  pointer-events: none; /* Ensures clicks pass through to the table */
}

.black-gradient {
  background: radial-gradient(
      232.36% 214.3% at 124.11% -92.78%,
      rgba(218, 227, 242, 0.2) 0%,
      rgba(102, 122, 155, 0) 100%
    ),
    #000;
}

.glow-box,
.glow-box_gold {
  position: relative;
}

.glow-box::before,
.glow-box_gold::before {
  content: '';
  position: absolute;
  inset: 0.75rem;
  border-radius: inherit;
  background: linear-gradient(to right, #88b4f5, transparent, #88b4f5);
  opacity: 0.5;
  filter: blur(1.25rem);
  pointer-events: none;
}

.glow-box_gold::before {
  background: linear-gradient(to right, #f5d788, transparent, #f7ff17);
}

@layer utilities {
  .mask-fade {
    --mask-start: 5%;
    --mask-end: 95%;
    mask-image: linear-gradient(to bottom, transparent, black var(--mask-start), black var(--mask-end), transparent);
    -webkit-mask-image: linear-gradient(
      to bottom,
      transparent,
      black var(--mask-start),
      black var(--mask-end),
      transparent
    );
  }

  .bg-mobile-active-gradient {
    background: radial-gradient(306.94% 306.94% at 50% 250%, rgba(166, 210, 255, 0.5) 0%, rgba(166, 210, 255, 0) 100%);
  }

  .layout-shadow {
    box-shadow: inset 0px 0px 20px 0px rgba(166, 210, 255, 0.2);
  }

  .layout-shadow-top-mobile {
    box-shadow: 0px 13px 18px -10px rgba(166, 210, 255, 0.2);
  }

  .layout-shadow-top-right {
    box-shadow: -13px 11px 20px #a6d2ff1c;
  }

  .layout-shadow-top-left {
    box-shadow: 15px 14px 20px -15px rgba(166, 210, 255, 0.2);
  }

  .layout-shadow-bottom-right {
    box-shadow: -8px -14px 20px -10px #a6d2ff2e;
  }

  .mobile-connect-shadow {
    background: radial-gradient(306.94% 306.94% at 50% 250%, rgba(166, 210, 255, 0.32) 0%, rgba(166, 210, 255, 0) 100%);
  }

  .active-link-shadow {
    background: radial-gradient(49.93% 24.26% at 50% 100%, rgba(136, 180, 245, 0.32) 0, rgba(136, 180, 245, 0) 100%);
  }

  .secondary-button-background {
    background: radial-gradient(306.94% 306.94% at 50% 250%, rgba(165, 243, 252, 0.32) 0%, rgba(165, 243, 252, 0) 100%);
  }

  .secondary-button-background:hover {
    background: radial-gradient(
      306.94% 306.94% at 50% 250%,
      rgba(165, 243, 252, 0.32) 0%,
      rgba(165, 243, 252, 0.1) 100%
    );
  }

  .video-position {
    object-position: 33% 38%;
  }

  @keyframes typing-first-line {
    0% {
      content: '_';
    }
    12.5% {
      content: 'E_';
    }
    25% {
      content: 'EN_';
    }
    37.5% {
      content: 'ENA_';
    }
    50% {
      content: 'ENAB_';
    }
    62.5% {
      content: 'ENABL_';
    }
    75% {
      content: 'ENABLI_';
    }
    87.5% {
      content: 'ENABLIN_';
    }
    100% {
      content: 'ENABLING_';
    }
  }

  @keyframes typing-second-line {
    0% {
      content: '';
    }
    3% {
      content: '_';
    }
    7.69% {
      content: 'I_';
    }
    15.38% {
      content: 'IN_';
    }
    23.07% {
      content: 'INT_';
    }
    30.76% {
      content: 'INTE_';
    }
    38.46% {
      content: 'INTER_';
    }
    46.15% {
      content: 'INTERN_';
    }
    53.84% {
      content: 'INTERNE_';
    }
    61.53% {
      content: 'INTERNET_';
    }
    69.23% {
      content: 'INTERNET _';
    }
    76.92% {
      content: 'INTERNET M_';
    }
    84.61% {
      content: 'INTERNET MO_';
    }
    92.30% {
      content: 'INTERNET MON_';
    }
    100% {
      content: 'INTERNET MONEY_';
    }
  }

  @keyframes typing-german-first-line {
    0% {
      content: '_';
    }
    7.5% {
      content: 'U_';
    }
    15% {
      content: 'US_';
    }
    22.5% {
      content: 'USDe_';
    }
    30% {
      content: 'USDe:_';
    }
    37.5% {
      content: 'USDe: _';
    }
    45% {
      content: 'USDe: E_';
    }
    52.5% {
      content: 'USDe: EN_';
    }
    60% {
      content: 'USDe: ENA_';
    }
    67.5% {
      content: 'USDe: ENAB_';
    }
    75% {
      content: 'USDe: ENABL_';
    }
    82.5% {
      content: 'USDe: ENABLI_';
    }
    90% {
      content: 'USDe: ENABLIN_';
    }
    97.5% {
      content: 'USDe: ENABLING_';
    }
    100% {
      content: 'USDe: ENABLING_';
    }
  }

  .typewriter-first-line::before {
    content: 'ENABLING';
    animation: typing-first-line;
    animation-duration: 1s;
  }

  .typewriter-second-line::before {
    content: '';
    animation: typing-second-line 1s;
    animation-duration: 1s;
    animation-delay: 1s;
  }

  .typewriter-german-first-line::before {
    content: 'USDe: ENABLING';
    animation: typing-german-first-line 1s;
  }
}

.button-middle-normal::after,
.button-middle-normal::before {
  background: #88b4f5;
}
.button-middle-medium-background::before,
.button-middle-medium-background::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 23px;
  height: 34px;
  clip-path: path(
    'M23 0H22.2118C20.8031 0 19.4598 0.594266 18.5121 1.63664L1.3003 20.5697C0.463609 21.49 0 22.6892 0 23.933V29C0 31.7614 2.23858 34 5 34H23V0Z'
  );
}
.button-middle-medium-background::after {
  rotate: 180deg;
  left: auto;
  right: 0px;
}
.button-middle-medium-foreground::before,
.button-middle-medium-foreground::after {
  content: '';
  position: absolute;
  top: 1px;
  left: 1.5px;
  width: 22px;
  height: 32px;
  clip-path: path(
    'M22 0L20.2456 0.584791C19.4291 0.856983 18.6966 1.33537 18.1191 1.97364L1.29232 20.5716C0.460567 21.491 0 22.6865 0 23.9262V27C0 29.7614 2.23858 32 5 32H22V0Z'
  );
  background: #000;
  z-index: 1;
}
.button-middle-medium-foreground::after {
  rotate: 180deg;
  left: auto;
  right: 1px;
}
.button-middle-medium-background {
  margin: 0 23px;
}

.button-middle-small-background::before,
.button-middle-small-background::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 16px;
  height: 24px;
  clip-path: path(
    'M3 24H16V0C14.6719 0 13.4066 0.565232 12.5205 1.55438L1.27598 14.1052C0.454343 15.0223 0 16.2103 0 17.4416V21C0 22.6569 1.34315 24 3 24Z'
  );
}
.button-middle-small-background::after {
  rotate: 180deg;
  left: auto;
  right: 0px;
}
.button-middle-small-foreground::before,
.button-middle-small-foreground::after {
  content: '';
  position: absolute;
  top: 1px;
  left: 1.5px;
  width: 15px;
  height: 22px;
  clip-path: path(
    'M15 0L14.2033 0.267794C13.3848 0.542904 12.6514 1.0253 12.0748 1.66801L1.27851 13.7001C0.455307 14.6176 0 15.8068 0 17.0394V19C0 20.6569 1.34315 22 3 22H15V0Z'
  );
  background: #000;
  z-index: 1;
}
.button-middle-small-foreground::after {
  rotate: 180deg;
  left: auto;
  right: 1px;
}
.button-middle-small-background {
  margin: 0 16px;
}

.button-middle-large-background::before,
.button-middle-large-background::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 33px;
  height: 52px;
  clip-path: path(
    'M33 0H32.1662C30.7841 0 29.4637 0.572043 28.5185 1.5803L1.35231 30.5575C0.483504 31.4843 0 32.7069 0 33.9772V47C0 49.7614 2.23858 52 5 52H33V0Z'
  );
}
.button-middle-large-background::after {
  rotate: 180deg;
  left: auto;
  right: 0px;
}
.button-middle-large-foreground::before,
.button-middle-large-foreground::after {
  content: '';
  position: absolute;
  top: 1px;
  left: 1.5px;
  width: 32px;
  height: 50px;
  clip-path: path(
    'M32 0L30.2035 0.598821C29.4137 0.862102 28.7021 1.31845 28.1333 1.92645L1.34864 30.5583C0.482096 31.4847 0 32.7057 0 33.9741V45C0 47.7614 2.23858 50 5 50H32V0Z'
  );
  background: #000;
  z-index: 1;
}
.button-middle-large-foreground::after {
  rotate: 180deg;
  left: auto;
  right: 1px;
}
.button-middle-large-background {
  margin: 0 33px;
}

.button-primary-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  clip-path: polygon(10% 0, 50% 30%, 100% 0, 90% 100%, 50% 80%, 0% 100%);
}
.button-primary-shadow-normal {
  background: #88b4f5;
}

.button-primary-shadow-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  filter: blur(10px);
}

.yield-box {
  display: inline-block;
  border-radius: 6px;
  position: relative;
  z-index: 1;
  background: linear-gradient(to bottom, #88b4f5 -20%, rgba(136, 180, 245, 0.4) 100%);
}
.yield-box::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: radial-gradient(222.89% 221.25% at 50% -56.25%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    #000000;
  border-radius: 6px;
  z-index: -1;
}
